import * as React from "react";
import { SelectInput, Edit, SimpleForm, TextInput, BooleanInput, SimpleFormIterator, ReferenceInput, useSimpleFormIteratorItem, ArrayInput, TabbedForm, FormTab, RadioButtonGroupInput, useDataProvider, useNotify, useRecordContext, Button, FormDataConsumer, useRefresh } from 'react-admin';
import {applicationTypeOptions, caseTypeOptions} from "../cases/CaseList";
import { ReferenceManyInput } from '@react-admin/ra-relationships';
import {Box, Stack } from "@mui/system";
import { actionTypeOptions } from "../cases/actions/utils";
import { useFormContext, useWatch } from "react-hook-form";

const applicationTypeOptionsIncludingAll = [{ id: 'All', name: 'All'}, ...applicationTypeOptions];
const responsibleRoleOptions = ["PATENT_ATTORNEY", "PARALEGAL", "ANNUITIES", "SYSTEM"].map((type) => ({ id: type, name: type}));
const startDateLogicOptions = [
    "DATE_OF_TODAY",
    "SAME_AS_DUE_DATE",
    "3M_BEFORE_DUE_DATE",
    "4M_BEFORE_DUE_DATE",
    "7D_AFTER_START_DATE",
    "14D_AFTER_START_DATE",
].map((type) => ({ id: type, name: type}))
const dueDateLogicOptions = [
    "SAME_AS_DUE_DATE",
    "3M_BEFORE_DUE_DATE",
    "4M_BEFORE_DUE_DATE",
    "7D_AFTER_START_DATE",
    "14D_AFTER_START_DATE",
].map((type) => ({ id: type, name: type}))
const extractFieldOptions = ["letter_date"].map((type) => ({ id: type, name: type}))

const actionSubRuleTypeOptions = [
    { id: 'industry', name: 'Industry' },
    { id: 'agent', name: 'Agent' }
];

interface RelatedRecord {
    code: string;
    status: string;
    action_name: string;
    responsible_role?: string;
}

export const AGENT_TYPE = 'agent';
export const INDUSTRY_TYPE = 'industry';

const CopyIndustryRulesToAgent = () => {
    const record = useRecordContext();
    const actionId = record?.id;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleCopyRules = async () => {
        if (!actionId) {
            notify("No action ID found", { type: 'warning' });
            return;
        }

        setLoading(true);

        try {

            const { data: industryRules } = await dataProvider.getList('action_sub_rules', {
                filter: { action_rule_id: actionId, action_sub_rule_type: INDUSTRY_TYPE },
                sort: { field: 'code', order: 'ASC' },
                pagination: { page: 1, perPage: 100 },
            });
            notify(`Copied ${industryRules.length} industry rules to agent`);
            console.log("Industry Rules:", industryRules);

            const agentRules = industryRules.map(rule => ({
                ...rule,
                id: undefined, // Remove the id to allow creation of a new record
                action_sub_rule_type: AGENT_TYPE,
            }));

            const createPromises = agentRules.map(rule =>
                dataProvider.create('action_sub_rules', { data: rule })
            );

            await Promise.all(createPromises);

            refresh();
        } catch (error) {
            // @ts-ignore
            notify(`Error fetching industry rules: ${error.message}`, { type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Button
            label={loading ? 'Copying...' : 'Copy Industry Rules to Agent'}
            onClick={handleCopyRules}
            variant="contained"
            color="primary"
            disabled={loading}
        />
    )
}

const CC = () => {
    const record = useFormContext();
    //const actionSubRuleType = record?.action_sub_rule_type;
    console.log("RECORC CONTETD");
    console.log(record);
    return (
        <div>Selected Action Sub Rule Type: </div>
    )
}


export const ActionRuleEdit = () => {
    const [showIndustryOrAgent, setShowIndustryOrAgent] = React.useState(true);
    const toggleIndustryOrAgent = () => setShowIndustryOrAgent(!showIndustryOrAgent);
    return (
        <Edit mutationMode="pessimistic">
            <SimpleForm>
                <Stack direction={"row"} spacing={2} flexWrap="wrap">
                    <TextInput source="action_code" />
                    <TextInput source="status" multiline/>
                    <TextInput source="action_name" multiline/>
                    <SelectInput source={"responsible_role"} choices={responsibleRoleOptions}/>
                    <SelectInput source="extract_field" choices={extractFieldOptions} />
                    <SelectInput source={"action_type"} choices={actionTypeOptions}/>
                    <SelectInput source={"case_type"} choices={caseTypeOptions} defaultValue={caseTypeOptions[0]}/>
                    <SelectInput source={"application_type"} choices={applicationTypeOptionsIncludingAll}/>
                    <ReferenceInput reference="content" source="content_id" label="Info content">
                        <SelectInput source={"content_id"} label={"Content"} optionText={"title"}/>
                    </ReferenceInput>
                    <BooleanInput source={"application_type_inverse"} label={"Inverse application type filter (i.e NOT *application type*)"}/>
                    <BooleanInput source={"active"} />
                </Stack>
                <Box width={"100%"} maxWidth={"400px"}>
                    <TextInput source="description_for_ai" label={"Description for AI"} multiline fullWidth />
                    <TextInput source="attributes_for_ai" label={"Attributes for AI (comma separated)"} multiline fullWidth />
                </Box>

                <CopyIndustryRulesToAgent/>
                <ReferenceManyInput
                    reference="action_sub_rules"
                    target="action_rule_id"
                    sort={{ field: 'action_sub_rule_type,code', order: 'desc,asc' }}
                >
                    {/**<SimpleFormIterator inline>
                        <TextInput source="code" required />
                        <TextInput source="status" multiline />
                        <TextInput source="action_name" multiline required />
                        <SelectInput source="responsible_role" choices={responsibleRoleOptions} required />
                        <ReferenceInput reference="email_templates" source="email_template_id" label="Email template">
                            <SelectInput source="name" label="Email template" optionText="name" />
                        </ReferenceInput>
                        <SelectInput source="internal_start_date_logic" choices={startDateLogicOptions} required />
                        <SelectInput source="internal_due_date_logic" choices={dueDateLogicOptions} required />
                        <TextInput source="complete_button_text" />
                        <BooleanInput source="inactivates_action_rule" />
                        <SelectInput source="action_sub_rule_type" choices={actionSubRuleTypeOptions} defaultValue="industry" required />
                        <CC></CC>
                    </SimpleFormIterator>
    */}
                    <SimpleFormIterator inline>
                        {
                            actionSubRuleInputFields
                        }
                    </SimpleFormIterator>
                </ReferenceManyInput>
            </SimpleForm>
        </Edit>
    );
};

export const actionSubRuleInputFields = [
    <TextInput source="code" required />,
    <TextInput source="status" multiline />,
    <TextInput source="action_name" multiline required />,
    <SelectInput source="responsible_role" choices={responsibleRoleOptions} required />,
    <ReferenceInput reference="email_templates" source="email_template_id" label="Email template">
        <SelectInput source="name" label="Email template" optionText="name" />
    </ReferenceInput>,
    <SelectInput source="internal_start_date_logic" choices={startDateLogicOptions} required />,
    <SelectInput source="internal_due_date_logic" choices={dueDateLogicOptions} required />,
    <TextInput source="complete_button_text" />,
    <BooleanInput source="inactivates_action_rule" />,
    <SelectInput source="action_sub_rule_type" choices={actionSubRuleTypeOptions} defaultValue="industry" required />,
];