import {BulkDeleteButton, Datagrid, DateField, EditButton, FunctionField, ReferenceManyField, } from "react-admin";
import {useIsAdmin} from "../../auth/utils";
import {SubActionsList} from "../actions/SubActionsList";
import {compactTable} from "../CaseList";
import {CaseActionsDelete, inactivatePaid, NextSubAction} from "../crud/CaseActionsList";
import {
    AnnuityAbandonedField,
    AnnuityPaidElsewhereField,
    AnnuityPaidField,
    InstructionsSentField
} from "../annuities/utils";


export const DesignRenewalsList = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <Datagrid
            {...props}
            bulkActionButtons={isAdmin ? <BulkDeleteButton mutationMode="pessimistic" /> : false}
            rowSx={inactivatePaid}
            sx={compactTable}
        >
            <FunctionField
                label="Name"
                render={(record: any) => record?.action_country_rule?.override_action_name ? record?.action_country_rule?.override_action_name : record?.action_rule?.action_name }
            />
            <FunctionField
                label="Due date"
                render={(record: any) => record?.due_date }
            />
            <DateField source={"with_fine_due_date"}></DateField>

            <FunctionField
                label="Status"
                render={(record: any) =>
                    record.annuity_payment_paid ? <AnnuityPaidField/> :
                        record.annuity_payment_instructions_sent ? <InstructionsSentField/> :
                            record.annuity_abandoned_by ? <AnnuityAbandonedField/> :
                                record.annuity_paid_elsewhere_by ? <AnnuityPaidElsewhereField/> : null
                }/>

            { isAdmin && <EditButton />}
            { isAdmin && <CaseActionsDelete />}
        </Datagrid>
    )
}
