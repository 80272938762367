import * as React from "react";
import {AutocompleteInput, BulkDeleteButton, Datagrid, DateField, Filter,
    FunctionField, Link, List, ReferenceArrayInput, ReferenceInput, SelectInput, TextField} from 'react-admin';
import {useIsAdmin} from "../auth/utils";
import {Flag} from "../utils/Flag";
import {countries, euFlagCountries} from "../utils/countries";
import { Box, Stack } from "@mui/system";
import {CurrencyField} from "../cases/select-countries/common";
import moment from "moment";

const Filters = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <Filter {...props}>
            <ReferenceInput source="tenant_id" reference="tenants" alwaysOn>
                <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Client"
                                   fullWidth/>
            </ReferenceInput>
            <ReferenceInput label="Order" reference="orders" source="id" >
                <AutocompleteInput filterToQuery={(searchText: string) => ({id: `${searchText}`})} label="Order"
                                   optionText={(record: any) => `${record.order_type} – ${moment(record.created_at).format("DD.MM.YY HH:mm")}`}
                                   fullWidth/>
            </ReferenceInput>
            <SelectInput
                source={"order_type"}
                choices={["PCT", "EP"].map((type) => ({id: type, name: type}))}
                alwaysOn
            />
        </Filter>
    );
};

export const OrdersList = (props: any) => (
    <List {...props} sort={{ field: "created_at", order: "ASC" }} filters={<Filters/>} >
        <Datagrid
            bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
        >
            <TextField label="Client" source={"tenant.name"} ></TextField>
            <DateField source={"created_at"} showTime />
            <DateField source={"updated_at"} showTime />
            <TextField source={"order_type"} />
            <TextField source={"pct_order.case.case_ref"} label={"Case ref."}/>
            <TextField source={"pct_order.case.application_number"} label={"App. #"} />
            <TextField label="Submitted by" source={"user.name"} ></TextField>
            <TextField label={"User ref"} source={"pct_order.user_reference"} ></TextField>
            <TextField label={"User comments"} source={"pct_order.user_comments"} ></TextField>
            <TextField source={"portal_price"} ></TextField>
            <FunctionField label={"Answers"} render={(record: any) => {
                return (
                    <>
                        {
                            record.pct_order?.answers?.map((answer: any) => (
                                <Stack mb={2}>
                                    <Box>{answer.q}</Box>
                                    <Box fontWeight={"600"}>{answer.a}</Box>
                                </Stack>
                            ))
                        }
                        {
                            record.pct_order?.pct_order_lines?.filter((line: any) => line.answers?.length > 0).map((order_line: any) => (
                                <>
                                    <Box fontWeight={"600"}><Flag countryCode={order_line.country_code}></Flag> {" " + countries[order_line.country_code]}</Box>
                                    {order_line.answers?.map((answer: any) => (
                                        <Stack mb={2}>
                                            <Box>{answer.q}</Box>
                                            <Box fontWeight={"600"}>{answer.a}</Box>
                                        </Stack>
                                    ))}
                                </>
                            ))
                        }
                    </>
                );
            }}></FunctionField>
            <FunctionField label={"Selected countries"} render={(record: any) => {
                return record.pct_order?.pct_order_lines?.map((line: any) => (
                    <Stack mb={2}>
                        <Stack direction={"row"} spacing={2}>
                            <span>
                                <Flag countryCode={euFlagCountries.includes(line.country_code) ? "eu" : line.country_code}></Flag>{"  " + countries[line.country_code]}
                            </span>
                            {
                                line.price &&
                                <span>({line.price} {line.price_currency})</span>
                            }
                        </Stack>
                        <Box sx={{ textWrap: "nowrap" }}>Official: {line.official_fee}. Service: {line.service_fee}. Translation: {line.translation_fee || "N/A"}</Box>
                    </Stack>
                ));
            }}></FunctionField>
            <FunctionField label={"Cases"} render={(record: any) => {
                return record.pct_order?.case?.national_phase_cases.map((line: any) => (
                    <Stack direction={"row"} spacing={2}>
                        <span>
                            <Link to={`/cases/${line.id}/show`} target={"_blank"}>{line.case_ref}</Link>
                        </span>
                    </Stack>
                ));
            }}></FunctionField>
        </Datagrid>
    </List>
);
