import buildHasuraProvider from "ra-data-hasura";
import customBuildFields from "./customBuildFields";
import {CreateActionsBody} from "../common/common-utils";
import {ActionCountryRuleCreateType, ActionRuleCreateType, CasesCreateType} from "../App";
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { DataProvider, UpdateParams, withLifecycleCallbacks } from 'react-admin';
import { setContext } from '@apollo/client/link/context';
import gql from 'graphql-tag';
const convertFileToBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file.rawFile);
    });

const httpLink = (auth0Data: any) => createHttpLink({
    uri: 'https://breeze-ip.hasura.app/v1/graphql',
});
const authLink = (auth0Data: any) => setContext(async (_, { headers }) => {
    // const token = getTokenOrRedirectToLogin(auth0Data);
    let token = "";
    try {
        token = await auth0Data.getAccessTokenSilently({ cacheMode: "cache-only" });
    } catch (error: any) {
        console.log("access token not found", error);
        auth0Data.loginWithRedirect();
    }
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

const getTokenOrRedirectToLogin = async (auth0: any) => {
    // get the authentication token from local storage if it exists
    let token = "";
    try {
        token = await auth0.getAccessTokenSilently({ cacheMode: "cache-only" });
    } catch (error: any) {
        console.log("access token not found", error);
        auth0.loginWithRedirect();
    }

    return token;
}

const myClientWithAuth = (auth0: any) => {
    // let accessToken = "";
    // try {
    //     console.log(auth0);
    //     // debugger;
    //     // accessToken = "";
    //     accessToken = await auth0.getAccessTokenSilently({ cacheMode: "on" });
    // } catch (error: any) {
    //     console.log("access token not found", error);
    // }
    return new ApolloClient({
        uri: 'https://breeze-ip.hasura.app/v1/graphql',
        cache: new InMemoryCache(),
        link: authLink(auth0).concat(httpLink(auth0)),
        // headers: {
        //     ...(accessToken ? { 'Authorization': `Bearer ${accessToken}` } : {}),
        // },
    })
};

const createManyActionRulesMutation = `
mutation createActionRules($rules: [action_rules_insert_input!]!) {
  insert_action_rules(objects: $rules) {
    affected_rows
  }
}
`;

const actionCountryRuleQuery = `
query ActionCountryRule($action_rule_id: uuid!) {
  action_country_rules(where: {action_rule_id: {_eq: $action_rule_id}, country_code: { _eq: "no"}}) {
    agent_fee
    agent_fee_currency
    country_code
    days_until_due_date
    end_of_month_due_date
    months_until_due_date
    years_until_due_date
    official_fee
    official_fee_currency
    our_fee
    our_fee_currency
    trigger_date
  }
}
`;

const createManyActionCountryRulesMutation = `
mutation createCountryRules($rules: [action_country_rules_insert_input!]!) {
  insert_action_country_rules(objects: $rules) {
    affected_rows
  }
}
`

const createManyCasesMutation = `
mutation importCases($cases: [cases_insert_input!]!, $tenant_id: uuid!, $next_case_ref_serial_number: Int!, $user_id: uuid!, $search_text: String!) {
  insert_import_sessions_one(object: { tenant_id: $tenant_id, search_text: $search_text, imported_by_user: $user_id, cases: { data: $cases } }) {
    id
     cases_aggregate {
      aggregate {
        count
      }
    }
  }
  
  update_tenants_by_pk(pk_columns: {id: $tenant_id}, _set: {next_case_ref_serial_number: $next_case_ref_serial_number}) {
    id
  }
}
`;

const resourceName = (resource: string) => resource === "country_rules" ? "action_country_rules" : resource;

export const buildDataProvider = async (auth0Data: any, isAdmin: boolean) => {
    // const client = await myClientWithAuth(auth0Data);
    const client = myClientWithAuth(auth0Data);

    const dataProviderHasura: any = await buildHasuraProvider({
            client,
            introspection: { include: ["actions", "action"]}
        },
        // @ts-ignore
        { buildFields: customBuildFields(isAdmin) }
    ) as unknown;

    // @ts-ignore
    const modifiedProvider: DataProvider = {
        getList: async (resource, params) => dataProviderHasura.getList(resourceName(resource), params),
        getOne: (resource, params) => dataProviderHasura.getOne(resourceName(resource), params),
        getMany: (resource, params) =>
            dataProviderHasura.getMany(resourceName(resource), params),
        getManyReference: (resource, params) =>
            dataProviderHasura.getManyReference(resourceName(resource), params),
        update: (resource, params) => {
            console.log(resource, params.data, params.previousData, JSON.stringify(params.data) === JSON.stringify(params.previousData));
            if (resource === "action_rules" || resource=== "action_sub_rules" || resource === "cases" || resource === "users") {
                if (JSON.stringify(params.data) === JSON.stringify(params.previousData)) {
                    // Bug in ra-data-hasura causing update to be called even if no changes are made.
                    // Hasura gives an error in this case, which is not what we want.
                    // Resolve with the existing data instead so the action_sub_rules can be updated as well
                    return Promise.resolve({ data: params.data });
                }
            }
            return dataProviderHasura.update(resourceName(resource), params);
        },
        updateMany: (resource, params) =>
            dataProviderHasura.updateMany(resourceName(resource), params),
        create: (resource, params) => {
            // Never send created_at and updated_at to Hasura
            const { created_at, updated_at, ...restData } = params.data;
            return dataProviderHasura.create(resourceName(resource), { ...params, data: restData });
        },
        delete: (resource, params) => dataProviderHasura.delete(resourceName(resource), params),
        deleteMany: (resource, params) =>
            dataProviderHasura.deleteMany(resourceName(resource), params),
        createManyActionCountryRules: async (rules: Array<ActionCountryRuleCreateType>) => fetch("https://breeze-ip.hasura.app/v1/graphql", {
            method: "POST",
            body: JSON.stringify({
                query: createManyActionCountryRulesMutation,
                variables: { rules }
            }),
            // @ts-ignore
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${await getTokenOrRedirectToLogin(auth0Data)}` },
        }).then(res => res.json()),
        createManyActionRulesMutation: async (rules: Array<ActionRuleCreateType>) => fetch("https://breeze-ip.hasura.app/v1/graphql", {
            method: "POST",
            body: JSON.stringify({
                query: createManyActionRulesMutation,
                variables: { rules }
            }),
            // @ts-ignore
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${await getTokenOrRedirectToLogin(auth0Data)}` },
        }).then(res => res.json()),
        actionCountryRule: async (action_rule_id: string) => fetch("https://breeze-ip.hasura.app/v1/graphql", {
            method: "POST",
            body: JSON.stringify({
                query: actionCountryRuleQuery,
                variables: { action_rule_id }
            }),
            // @ts-ignore
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${await getTokenOrRedirectToLogin(auth0Data)}` },
        }).then(res => res.json()),
        createManyCasesMutation: async (cases: Array<CasesCreateType>, tenant_id: string, next_case_ref_serial_number: number, user_id: string, search_text: string) => fetch("https://breeze-ip.hasura.app/v1/graphql", {
            method: "POST",
            body: JSON.stringify({
                query: createManyCasesMutation,
                variables: { cases, tenant_id, next_case_ref_serial_number, user_id, search_text }
            }),
            // @ts-ignore
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${await getTokenOrRedirectToLogin(auth0Data)}` },
        }).then(res => res.json()),
        createAnnuitiesOrder: async (caseActionIds: Array<string>, tenant_id: string, currency_code?: string) => fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/createAnnuitiesOrder`, {
            method: "POST",
            body: JSON.stringify({
                caseActionIds,
                tenant_id,
                // currency_code: tenant_id === "6730e5a1-e930-44fa-9791-dddf42fc4358" ? "USD" : "NOK",
                currency_code: currency_code || "NOK",
            }),
            // @ts-ignore
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${await getTokenOrRedirectToLogin(auth0Data)}` },
        }).then(res => res.json()),
        createActions: async (body: CreateActionsBody) => fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/createActions`, {
            method: "POST",
            body: JSON.stringify(body),
            // @ts-ignore
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${await getTokenOrRedirectToLogin(auth0Data)}` },
        }).then((res) => {
            console.log("RESULT");
            console.log(res);
            return res;
        }).then(res => res.json()),
        processDocument: async (id: string) => fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/processPDF-background?docketingDraftsId=${id}`, {
            method: "GET",
            // @ts-ignore
            headers: { 'Authorization': `Bearer ${await getTokenOrRedirectToLogin(auth0Data)}` },
        }),
        hasuraRequest: async (body: { query: string, variables: object | undefined }) => {
            console.log(client);
            if (body.query.trimStart().startsWith("mutation")) {
                return client.mutate({
                    mutation: gql`${body.query}`,
                    variables: body.variables,
                });
            } else {
                return client.query({
                    query: gql`${body.query}`,
                    variables: body.variables,
                });
            }
        } };


    return withLifecycleCallbacks(modifiedProvider, [
        {
            resource: 'cases',
            beforeUpdate: async (params: UpdateParams<any>, dataProvider: DataProvider<string>) => {
                // Freshly dropped pictures are File objects and must be converted to base64 strings

                console.log(params.data);
                const isNewLogo = params.data.trademark_logo?.rawFile?.type;

                let base64Logo = params.data.trademark_logo?.src;
                if (isNewLogo) {
                    base64Logo = await convertFileToBase64(params.data.trademark_logo);
                }
                // const newPictures = params.data.pictures.filter((p: any) => p.rawFile instanceof File);
                // const formerPictures = params.data.pictures.filter((p: any) => !(p.rawFile instanceof File));
                //
                // const base64Pictures = await Promise.all(newPictures.map(convertFileToBase64))
                // const pictures = [
                //     ...base64Pictures.map((dataUrl, index) => ({
                //         src: dataUrl,
                //         title: newPictures[index].name,
                //     })),
                //     ...formerPictures,
                // ];

                const updateObj = { ...params, data: { ...params.data, trademark_logo: { src: base64Logo, title: params.data.trademark_logo?.title } } };
                console.log(updateObj);
                return updateObj;
            }
        }
    ]);
};