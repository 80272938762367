import React, {useEffect, useState} from 'react';
import './App.css';
import { LoginForm, Resource, CustomRoutes, DataProvider, withLifecycleCallbacks, UpdateParams, List, Datagrid, DateField, ReferenceField, TextField, NumberField, ReferenceManyField, ReferenceOneField, Button, Link, TopToolbar, Create, SimpleForm, TextInput, DateInput, ReferenceInput, AutocompleteInput, CloneButton, Edit, SelectInput, EditButton, BooleanInput, Toolbar, SaveButton, BooleanField, WithRecord, addRefreshAuthToAuthProvider, addRefreshAuthToDataProvider } from 'react-admin';
import { Admin } from '@react-admin/ra-enterprise';
import { ContainerLayout } from '@react-admin/ra-navigation';
import {CASE_FIELDS, CaseIcon, CaseList, caseTypeOptions} from "./cases/CaseList";
import {NameCreate, NameEdit, NameIcon, NameList} from "./names/names";
import {
    CitationSpecificFields,
    DOCUMENT_FIELDS,
    DocumentCreate,
    DocumentEdit,
    documentTypes,
} from "./documents/documents";
import {Auth0ContextInterface, useAuth0} from "@auth0/auth0-react";
import auth0AuthProvider from './auth/auth0AuthProvider';
import LoginPage from './auth/LoginPage';
import {Route,  } from "react-router";
import {CaseActionsEdit} from "./cases/crud/CaseActionsList";
import {UserList} from "./users/UserList";
import {TenantList} from "./tenants/TenantList";
import {AppLayout} from "./react-admin-overrides/AppLayout";
import {AnnuityList} from "./cases/annuities/Annuities";
import {ActionRuleList} from "./actions/ActionRuleList";
import {ActionCountryRuleList, ActionRulesShow} from "./actions/ActionCountryRuleList";
import {ActionCountryRuleEdit} from "./actions/ActionCountryRuleEdit";
import {TenantCreate} from "./tenants/TenantCreate";
import {TenantEdit} from "./tenants/TenantEdit";
import customBuildFields from "./utils/customBuildFields";
import {ExtendedActionCountryRuleList} from "./actions/ExtendedActionCountryRuleList";
import {ActionRuleEdit} from "./actions/ActionRuleEdit";
import {ActionRuleCreate} from "./actions/ActionRuleCreate";
import {ExchangeRateList} from "./exchange-rates/ExchangeRatesList";
import {ActionsList} from "./cases/actions/Actions";
import {casesImportSessionUrl, ImportCases} from "./cases/import/importCases";
import {UserEdit} from "./users/UserEdit";
import {PricesList} from "./actions/PricesList";
import {useIsAdmin} from "./auth/utils";
import {Spinner} from "./utils/Spinner";
import {AnnuityOrder} from "./cases/annuities/AnnuityOrder";
import {AnnuityOrders} from "./cases/annuities/AnnuityOrders";
import {CaseEdit} from "./cases/crud/CaseEdit";
import {CaseCreate} from "./cases/crud/CaseCreate";
import {CaseShow} from "./cases/crud/CaseShow";
import { Docketing } from './docketing/Docketing';
import {TaskList} from "./tasks/TaskList";
import {CaseTeamCreate, CaseTeamEdit, TeamList} from "./teams/teams";

import {CreateActionsBody} from "./common/common-utils";
import {EmailTemplatesCreate, EmailTemplatesEdit, EmailTemplatesList} from "./email-templates/EmailTemplatesList";
import {UserCreate} from "./users/UserCreate";
import {LegalEventCountryCodesList} from "./legal-event-country-codes/LegalEventCountryCodes";
import {
    DocketingTestCaseCreate,
    DocketingTestCaseEdit,
    DocketingTestCasesList
} from "./docketing-test-cases/DocketingTestCases";
import {TrademarkList} from "./cases/TrademarkList";
import {TaskHistoryList} from "./tasks/TaskHistoryList";
import {SelectCountries} from "./cases/select-countries/SelectCountries";
import {InfringementCasesList, OppositionCasesList, OtherCasesList} from "./cases/OtherCasesList";
import {DesignCasesList} from "./cases/design/DesginCasesList";
import {EPValidationPricesList} from "./configuration/ep-validation/EPValidationPricesList";
import {SelectCountriesEP} from "./cases/select-countries/SelectCountriesEP";
import {PCTPricesCreate, PCTPricesList} from "./configuration/pct/PCTPricesList";
import {TermsAndConditions} from "./TermsAndConditions";
import { Dashboard } from './Dashboard';
import PublishIcon from '@mui/icons-material/Publish';
import {CreateActionsButton} from "./cases/actions/CreateActionsButton";
import {actionTypeOptions} from "./cases/actions/utils";
import {ClientInput} from "./utils/ClientInput";
import {EditTaskButton} from "./tasks/EditTaskButton";
import {Budget} from "./Budget";
import { Box } from '@mui/system';
import { EditInDialogButton } from '@react-admin/ra-form-layout';
import {ImportFromFile} from "./cases/import/importFromFile";
import {appTheme} from "./utils/theme";
import {AnnuitiesManagementPeriod} from "./cases/annuities/AnnuitiesManagementPeriod";
import {buildDataProvider} from "./utils/dataProvider";
import {InstructionsReceived} from "./cases/instructions/InstructionsReceived";
import {OrdersList} from "./orders/orders";
import {ContentCreate, ContentEdit, ContentList} from './content/content';
// import Auth0AuthProvider from "./auth/auth0AuthProvider";
// import { Auth0Client } from "@auth0/auth0-spa-js";
// import { Auth0AuthProvider } from 'ra-auth-auth0';

export type ActionRuleCreateType = {
    action_code: string,
    action_type: string,
    action_name: string,
    case_type: string,
    application_type: string,
    application_type_inverse: boolean,
    active: boolean,
    action_country_rules?: {
        data: Array<ActionCountryRuleCreateType>
    }
}



export type CasesCreateType = { [key in CASE_FIELDS]: any } & {
    citations?: {
        data: Array<{
        }>
    },
}

export type ActionCountryRuleCreateType = {
    country_code: string,
    trigger_date: string,
    days_until_due_date: number,
    months_until_due_date: number,
    years_until_due_date: number,
    end_of_month_due_date: boolean,
    official_fee: number,
    official_fee_currency: string,
    agent_fee: number,
    agent_fee_currency: string,
    our_fee: number,
    our_fee_currency: string,
};

// const auth0 = new Auth0Client({
//     domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
//     clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
//     cacheLocation: 'localstorage',
//     authorizationParams: {
//         redirect_uri: window.location.origin,
//         // scope: "openid profile email offline_access",
//         audience: process.env.REACT_APP_AUTH0_AUDIENCE,
//         connection: "email",
//     },
// });
//
// const authProvider = Auth0AuthProvider(auth0, {
//     loginRedirectUri: window.location.origin + "/callback",
//     logoutRedirectUri: window.location.origin,
// });

export const refreshAuth = (auth0Data: Auth0ContextInterface) => async () => {
    console.log("Refreshing auth if needed");
    console.log(auth0Data?.user);
    // This function will fetch the new tokens from the authentication service and update them in localStorage
    let accessToken = "";
    try {
        accessToken = await auth0Data.getAccessTokenSilently();
    } catch (error: any) {
        console.log("access token not found", error);
        auth0Data.loginWithRedirect();
    }
    console.log("Got access token", accessToken);
    return Promise.resolve();
}

const App = () => {
    const [dataProvider, setDataProvider] = useState<any>(null);
    const auth0Data = useAuth0<any>();
    const isAdmin = useIsAdmin();

    useEffect(() => {
        console.log("Building data provider");
        buildDataProvider(auth0Data, isAdmin)
            .then((provider) => {
                setDataProvider(provider);
            }).catch((error) => {
                // TODO: Consider if this needs to be handled. What happens if the dataprovider creation fails?
                console.error("DataProvider creation failed: ", error);
            });
    }, []);


    if (!dataProvider || auth0Data.isLoading) return <Spinner/>;

  return (
    <Admin
        dataProvider={addRefreshAuthToDataProvider(dataProvider, refreshAuth(auth0Data))}
        // authProvider={authProvider}
        authProvider={addRefreshAuthToAuthProvider(auth0AuthProvider(auth0Data), refreshAuth(auth0Data))}
        loginPage={LoginPage}
        layout={AppLayout}
        theme={appTheme}
        dashboard={Dashboard}
        // requireAuth
        // layout={ContainerLayout}
    >
        <CustomRoutes>
            <Route path="/callback" element={<LoginForm />} />
            <Route path="/prices" element={<PricesList />} />
            <Route path="/docketing" element={<Docketing />} />
            <Route path="/trademarks" element={<TrademarkList />} id={"trademarks"} />
            {/*<Route path="/other-cases" element={<OtherCasesList/>} id={"other-cases"}/>*/}
            <Route path="/designs" element={<DesignCasesList />} id={"designs"} />
            <Route path="/other-cases/infringement" element={<InfringementCasesList />} id={"infringement"} />
            <Route path="/other-cases/opposition" element={<OppositionCasesList />} id={"opposition"} />
            <Route path="/import" element={<ImportCases />} />
            <Route path="/import-from-file" element={<ImportFromFile />} />
            <Route path="/budget" element={<Budget />} />
            <Route path="annuities-management" element={<AnnuitiesManagementPeriod />} />
        </CustomRoutes>
        <CustomRoutes noLayout>
            <Route path="/terms-pct" element={<TermsAndConditions />} />
            <Route path="/terms-ep" element={<TermsAndConditions />} />
        </CustomRoutes>
        {
            isAdmin && [
                <Resource
                    name="users"
                    list={UserList}
                    edit={UserEdit}
                    create={UserCreate}
                    icon={NameIcon}
                    recordRepresentation="name"
                />,
                <Resource
                    name="names"
                    list={NameList}
                    edit={NameEdit}
                    create={NameCreate}
                    icon={NameIcon}
                    recordRepresentation="name"
                />,
                <Resource
                    name="case_teams"
                    list={TeamList}
                    edit={CaseTeamEdit}
                    create={CaseTeamCreate}
                    recordRepresentation="name"
                />,
                <Resource
                    name="tenants"
                    options={{ label: 'Clients' }}
                    list={TenantList}
                    create={TenantCreate}
                    edit={TenantEdit}
                    icon={NameIcon}
                    recordRepresentation="name"
                />,
                <Resource
                    name="action_rules"
                    create={ActionRuleCreate}
                    list={ActionRuleList}
                    edit={ActionRuleEdit}
                    show={ActionRulesShow}
                >
                    <Route path=":actionRuleId/country_rules" element={<ActionCountryRuleList />} />
                    <Route path=":actionRuleId/country_rules/:actionCountryRuleId" element={<ActionCountryRuleEdit />} />
                </Resource>,
                <Resource
                    name="country_rules"
                    list={ExtendedActionCountryRuleList}>
                    <Route path=":actionCountryRuleId" element={<ActionCountryRuleEdit />} />
                </Resource>,
                <Resource
                    name="email_templates"
                    list={EmailTemplatesList}
                    create={EmailTemplatesCreate}
                    edit={EmailTemplatesEdit}
                >
                </Resource>,
                <Resource
                    name="legal_event_country_codes"
                    list={LegalEventCountryCodesList}
                />,
                <Resource
                    name="docketing_test_cases"
                    list={DocketingTestCasesList}
                    create={DocketingTestCaseCreate}
                    edit={DocketingTestCaseEdit}
                />,
                <Resource
                    name="exchange_rates"
                    list={ExchangeRateList}
                />
            ]
        }

        {
            // !isAdmin && [
            [
                <Resource
                    name="cases"
                    list={CaseList}
                    edit={CaseEdit}
                    create={CaseCreate}
                    show={CaseShow}
                    recordRepresentation={"case_ref"}
                    icon={CaseIcon}>
                    <Route path=":id/select-countries" element={<SelectCountries />} />
                    {/*<Route path="trademarks" element={<TrademarkList />} />*/}
                </Resource>,
                <Resource
                    name="annuities"
                    list={AnnuityList}>
                </Resource>,

                <Resource name="emails" />,
                <Resource name="case_roles"/>,
                <Resource name="annuity_orders"
                          show={AnnuityOrder}
                          list={AnnuityOrders}
                />,

                <Resource name="instructions_received"
                          list={InstructionsReceived}
                />,

                <Resource
                    name="case_documents"
                    edit={DocumentEdit}
                    create={DocumentCreate}
                    //list={DocumentList}
                />,
                <Resource
                    name="actions"
                    list={ActionsList}
                />,
                <Resource
                    name="tasks"
                    list={TaskList}>
                    <Route path="history" element={<TaskHistoryList />} />
                </Resource>,
                <Resource
                    name="case_actions"
                    edit={CaseActionsEdit}
                    // list={ActionsList}
                />,
                <Resource
                    name="orders"
                    list={OrdersList}
                />,
                <Resource
                    name="ep_validation_country_prices"
                    // edit={CaseActionsEdit}
                    list={EPValidationPricesList}
                />,
                <Resource
                    name="pct_country_prices"
                    create={PCTPricesCreate}
                    list={PCTPricesList}
                />,
                <Resource
                    name="content"
                    create={ContentCreate}
                    edit={ContentEdit}
                    list={ContentList}
                />,
                <Resource
                    name="import_sessions"
                    list={
                    <List sort={{ field: "created_at", order: "DESC" }} actions={<TopToolbar>
                        <Button
                            component={Link}
                            to={`/import`}
                            label="Import"
                        >
                            <PublishIcon></PublishIcon>
                        </Button>
                    </TopToolbar>}>
                        <Datagrid>
                            {/*<ReferenceManyField perPage={1} reference="cases" target="import_session_id">*/}
                            {/*    <TextField source={"case_ref"}/>*/}
                            {/*    <TextField source={"tenant_id"}/>*/}
                            {/*</ReferenceManyField>*/}
                            <ReferenceOneField reference="cases" target="import_session_id" label={"Client"}>
                                <ReferenceField reference="tenants" source={"tenant_id"}>
                                    <TextField source={"name"}/>
                                </ReferenceField>
                            </ReferenceOneField>
                            <DateField source={"created_at"} showTime label={"Imported at"}></DateField>
                            <ReferenceField reference="users" source={"imported_by_user"} label={"Imported by"}>
                                <TextField source={"name"}/>
                            </ReferenceField>
                            <NumberField source={"cases_aggregate.aggregate.count"} label={"# cases imported"}></NumberField>
                            <TextField source={"search_text"}/>
                            <BooleanField source={"trigger_flag"} looseValue={true}/>
                            <WithRecord render={(record: any) => (
                                <Button to={casesImportSessionUrl(record.id)} label={"View cases"} component={Link}></Button>
                            )}></WithRecord>
                            <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Trigger action creation`} label={`Trigger action creation`} icon={<></>}>
                                <Box sx={{minWidth: "600px"}}>
                                    <SimpleForm
                                        maxWidth={"sm"}
                                        toolbar={
                                            <Toolbar>
                                                <SaveButton label={"Save"} alwaysEnable />
                                            </Toolbar>
                                        }
                                    >
                                        <BooleanInput source={"trigger_flag"} />
                                    </SimpleForm>
                                </Box>
                            </EditInDialogButton>
                        </Datagrid>
                    </List>
                }
                />,
                <Resource
                    name="annuity_cost_forecasts"
                    create={
                        <Create >
                            <SimpleForm >
                                <ClientInput />
                                <TextInput source="name" />
                                <DateInput source="from"></DateInput>
                                <DateInput source="to"></DateInput>
                            </SimpleForm>
                        </Create>
                    }
                    edit={
                    <Edit>
                        <SimpleForm >
                            <ClientInput />
                            <TextInput source="name" />
                            <SelectInput source="status" choices={["TO_BE_CALCULATED", "CALCULATION_COMPLETE"].map((_: any) => ({ id: _, name: _}))} />
                            <DateInput source="from"></DateInput>
                            <DateInput source="to"></DateInput>
                        </SimpleForm>
                    </Edit>
                    }
                    list={
                        <List sort={{ field: "created_at", order: "DESC" }} filters={[
                            <ReferenceInput reference="tenants" source="tenant_id" label="Client">
                                <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label={"Client"} />
                            </ReferenceInput>,
                        ]}>
                            <Datagrid>
                                <DateField source={"created_at"} showTime label={"Created at"}></DateField>
                                <ReferenceField reference="tenants" source={"tenant_id"} label={"Client"}>
                                    <TextField source={"name"}/>
                                </ReferenceField>
                                <TextField source={"name"}/>
                                <TextField source={"status"}/>
                                <DateField source={"from"}></DateField>
                                <DateField source={"to"}></DateField>
                                <NumberField source={"number_of_annuities"} label={"# annuities"}></NumberField>
                                <NumberField source={"total_cost_nok"} ></NumberField>
                                <NumberField source={"total_cost_eur"} ></NumberField>
                                <ReferenceField reference="exchange_rates" source={"eur_exchange_rate_id"} label={"EUR Exchange Rate"}>
                                    <TextField source={"exchange_rate"} sx={{ textWrap: "nowrap" }} component={"div"}/>
                                    <TextField source={"date"} sx={{ textWrap: "nowrap" }} component={"div"}/>
                                </ReferenceField>
                                <NumberField source={"total_cost_usd"} ></NumberField>
                                <ReferenceField reference="exchange_rates" source={"usd_exchange_rate_id"} label={"USD Exchange Rate"}>
                                    <TextField source={"exchange_rate"} sx={{ textWrap: "nowrap" }} component={"div"}/>
                                    <TextField source={"date"} sx={{ textWrap: "nowrap" }} component={"div"}/>
                                </ReferenceField>
                                <CloneButton ></CloneButton>
                                <EditButton></EditButton>
                            </Datagrid>
                        </List>
                    }
                />,
            ]
        }
    </Admin>
  );
}

export default App;
