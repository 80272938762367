import * as React from "react";
import { List, Datagrid, TextField, useRecordContext, FunctionField, BooleanField, EditButton, DeleteButton, SelectInput, TextInput, ShowButton, BooleanInput, SimpleForm, SimpleFormIterator, ReferenceInput } from 'react-admin';
import { useParams, Link } from 'react-router-dom';
import {Box, Button } from '@mui/material';
import { CopyActionRuleButton } from "./CopyActionRuleButton";
import {CreateCountryRulesDialogButton} from "./CreateCountryRuleDialogButton";
import {actionTypeOptions} from "../cases/actions/utils";
import {caseTypeOptions} from "../cases/CaseList";
import {ShowContentInDialogButton} from "../content/ShowContentInDialogButton";
import {
    actionSubRuleInputFields,
} from "./ActionRuleEdit";
import {EditInDialogButton } from "@react-admin/ra-form-layout";
import { ReferenceManyInput } from "@react-admin/ra-relationships";

const activeFilterChoices = [{ id: true, name: "Only Active" }, { id: false, name: "Only Inactive" }];

const filters = [
    <TextInput source={"action_code"}  label={"Code"} alwaysOn />,
    <SelectInput source={"action_type"} choices={actionTypeOptions} label={"Type"} alwaysOn />,
    <TextInput source={"action_name"} alwaysOn />,
    <SelectInput source={"case_type"} alwaysOn choices={caseTypeOptions} />,
    <SelectInput source={"active"} alwaysOn choices={activeFilterChoices} emptyText={"Show active and inactive"} />,
];

export const ActionRuleList = (props: any) => {
    const { actionRuleId } = useParams();
    return (
        <List resource="action_rules" filters={filters} filter={{ id: actionRuleId }} sort={{ field: "action_code,action_name", order: "ASC,ASC" }} perPage={50}>
            <Datagrid bulkActionButtons={false}>
                <TextField source={"action_code"} />
                <TextField source={"action_type"} />
                <TextField source={"action_name"} />
                <TextField source={"case_type"} />
                <FunctionField
                    label="Application type"
                    render={(record: any) => record.application_type ? `${record.application_type_inverse ? "NOT " : ""} ${record.application_type}` : 'All' }
                />
                <BooleanField source={"active"} />
                <ShowButton label={"Country Rules"} icon={<></>}></ShowButton>
                {/*<CountryRulesButton/>*/}
                {/*<CreateCountryRulesButton/>*/}
                <EditButton />
                <CopyActionRuleButton />
                {/*<DeleteButton mutationMode="pessimistic"/>*/}
                <CreateCountryRulesDialogButton />
                <ShowContentInDialogButton />
                <EditInDialogButton title={"Edit Agency Workflow"} label={"Edit Industry Workflow"} fullWidth mutationMode={"pessimistic"} maxWidth={"xl"}>
                    <Box >
                        <SimpleForm>
                            <ReferenceManyInput
                                reference="action_sub_rules"
                                target="action_rule_id"
                                sort={{ field: 'action_sub_rule_type,code', order: 'desc,asc' }}
                                filter={{ action_sub_rule_type: "industry" }}
                            >
                                <SimpleFormIterator inline>
                                    {actionSubRuleInputFields}
                                </SimpleFormIterator>
                            </ReferenceManyInput>
                        </SimpleForm>
                    </Box>
                </EditInDialogButton>
                <EditInDialogButton title={"Edit Agency Workflow"} label={"Edit Agency Workflow"} fullWidth mutationMode={"pessimistic"} maxWidth={"xl"}>
                    <Box >
                        <SimpleForm>
                            <ReferenceManyInput
                                reference="action_sub_rules"
                                target="action_rule_id"
                                sort={{ field: 'action_sub_rule_type,code', order: 'desc,asc' }}
                                filter={{ action_sub_rule_type: "agent" }}
                            >
                                <SimpleFormIterator inline>
                                    {actionSubRuleInputFields}
                                </SimpleFormIterator>
                            </ReferenceManyInput>
                        </SimpleForm>
                    </Box>
                </EditInDialogButton>
            </Datagrid>
        </List>
    );
};

const CountryRulesButton = () => {
    const actionRule = useRecordContext();
    return (
        <Button
            component={Link}
            to={`/action_rules/${actionRule?.id}/country_rules`}
        >
            Country rules
        </Button>
    );
};
