import React, {FunctionComponent, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Alert, AlertTitle, Typography } from '@mui/material';
import {CloseDialogIconButton} from "./CloseDialogIconButton";

export const ErrorDialog: FunctionComponent = () => {
    const [open, setOpen] = useState(false);

    const onClick = (e: any) => {
        e?.preventDefault();
        setOpen(true);
    }
    const close = (e: any) => {
        e?.preventDefault();
        setOpen(false)
    };

    return (
        <Dialog open={open} onClose={close} fullWidth maxWidth="xl">
            <CloseDialogIconButton onClose={close}/>
            <DialogTitle>Error</DialogTitle>
            <DialogContent>
                <Alert severity="error" sx={{marginBottom: 2}}>
                    <AlertTitle>Error</AlertTitle>
                    <Typography>An error occurred while trying to submit your order. We're very sorry for the inconvenience and will look into the issue.</Typography>
                </Alert>
            </DialogContent>
            {/*<DialogActions>*/}
            {/*<Button onClick={close}>Cancel</Button>*/}
            {/*<Button onClick={startCopying}>Start copying</Button>*/}
            {/*</DialogActions>*/}
        </Dialog>
    );
}
