import * as React from "react";
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    BulkExportButton,
    Datagrid,
    DateField,
    DateInput,
    DateTimeInput,
    Filter,
    Form,
    FormDataConsumer,
    FunctionField,
    List,
    NullableBooleanInput,
    NumberField,
    NumberInput,
    Pagination,
    ReferenceArrayInput,
    ReferenceInput,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    useDataProvider,
    useRecordContext,
    useRefresh,
    useUnselectAll,
    WithListContext,
    WrapperField
} from 'react-admin';
import {Alert, AlertTitle, AppBar, Avatar, Badge, Box, Button, Chip, IconButton, Stack, Tooltip} from "@mui/material";
import {countries} from "../../utils/countries";
import {Link} from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/HelpOutline';
import moment from "moment";
import {compactTable} from "../CaseList";
import {useIsAdmin, useUserId} from "../../auth/utils";
import {SendPaymentInstructionsButton} from "./SendPaymentInstructionsButton";
import {Page} from "../../utils/Page";
import {CountryInput} from "../../utils/CountryInput";
import {BulkUpdateFormButton} from "@react-admin/ra-form-layout";
import {QuickFilter} from "../../utils/QuickFilter";
import {DepartmentInput, ProductInput} from "../crud/CaseInputFields";
import {TenantContext, useTenantContext} from "../../react-admin-overrides/AppLayout";
import {FlagField} from "../../utils/FlagField";
import {AnnuityManagementDialogButton} from "./AnnuitiesManagementDialog";
import {annuitiesManagementRowSx, AnnuityConclusionInput} from "./AnnuitiesManagementPeriod";
import {errorLight, successLight, warningLight} from "../../utils/theme";
import {CaseRefField} from "../crud/CaseRefField";
import {submitPaymentInstructionsMutation} from "./submitPaymentInstructions";
import {ReviewAndSubmitInstructionsDialog} from "./ReviewAndSubmitInstructionsDialog";
import {Spinner} from "../../utils/Spinner";
import {
    AnnuityAbandonedField,
    AnnuityPaidElsewhereField,
    AnnuityPaidField, AnnuityStatus,
    InstructionsConfirmedField,
    InstructionsSentField,
    ShowCountryRuleButton
} from "./utils";
import {FormProvider, useForm, useWatch } from "react-hook-form";
import {ButtonDialog} from "../../utils/ButtonDialog";
import {annuitiesExporter} from "./annuities-exporter";

export const dateFormat = "YYYY-MM-DD";

const logAndReturnTrue = (data: any) => {
    console.log(data);
    return true;
}

const AnnuityFilter = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <Filter {...props}>
            <DateInput source="effective_due_date@_gte" label="Due Date From" alwaysOn/>
            <DateInput source="effective_due_date@_lte" label="Due Date To" alwaysOn/>
            {isAdmin && <DateInput source="due_date@_gte" label="Original Due Date From" alwaysOn/>}
            {isAdmin && <DateInput source="due_date@_lte" label="Original Due Date To"  alwaysOn/>}
            {/*<DateInput source="with_fine_due_date@_gte" label="With Fine Due Date From" />*/}
            {/*<DateInput source="with_fine_due_date@_lte" label="With Fine Due Date To"  />*/}
            <CountryInput source={"country_code"} label={"Country"}/>
            <TextInput source="case_ref"></TextInput>
            <TextInput source="tenant_case_ref" label={"Client ref"}></TextInput>
            <TextInput source="application_number"></TextInput>
            <TextInput source="catchword"></TextInput>
            <TextInput source="registration_number"></TextInput>
            <TextInput source="applicant"></TextInput>
            <ProductInput source={"product"}></ProductInput>
            <DepartmentInput source={"department"}></DepartmentInput>
            { isAdmin &&
            <ReferenceInput reference="tenants" source="tenant_id" label="Client">
                <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label={"Client"} />
            </ReferenceInput>
            }
            {
                isAdmin &&
                <ReferenceArrayInput label="Multiple Case Refs" reference="cases" source="case_id" >
                    <AutocompleteArrayInput label="Multiple Case Refs" filterToQuery={(searchText: string) => ({case_ref: `${searchText}`})} sx={{ minWidth: 200 }} optionText={"case_ref"}/>
                </ReferenceArrayInput>
            }
            {/*<BooleanInput source={"completed_at@_is_null"} label={"Only unprocessed"} alwaysOn></BooleanInput>*/}
            <NullableBooleanInput
                source={"inactivated_at@_is_null"}
                label={"Instructions status"}
                trueLabel="Only non-instructed"
                falseLabel="Only instructed and confirmed"
                nullLabel="Show all"
                alwaysOn
                sx={{ minWidth: 250 }}
            />
            {/*<QuickFilter source={"completed_at@_is_null"} label={"Hide completed/paid"} defaultValue={true}></QuickFilter>*/}
            {/*<QuickFilter source={"completed_at@_is_null"} label={"Show completed/paid"} defaultValue={undefined}></QuickFilter>*/}
            {/*<QuickFilter source={"annuity_payment_instructions_sent"} label={"Only instructions sent"} defaultValue={true}></QuickFilter>*/}
            {/*{isAdmin &&*/}
            {/*    <BooleanInput source={"annuity_payment_instructions_sent"} label={"Show Instructions Sent"} alwaysOn></BooleanInput>*/}
            {/*}*/}
            {/*{isAdmin &&*/}
            {/*    <BooleanInput source={"annuity_payment_paid"} label={"Show Paid"} alwaysOn></BooleanInput>*/}
            {/*}*/}
        </Filter>
    );
};

const MarkPaidButton = () => {
    const annuityRecord = useRecordContext();
    const userId = useUserId();
    const now = moment().format();
    return (
        <BulkUpdateFormButton resource={"case_actions"} label={"Mark as paid"} icon={<></>}>
            <SimpleForm
                toolbar={
                    <Toolbar>
                        <SaveButton label={"Save"} alwaysEnable />
                    </Toolbar>
                }
                sx={{maxWidth: 400}} defaultValues={{
                completed_by: userId,
                completed_at: now,
                annuity_payment_paid: true,
                inactivated_by: userId,
                inactivated_at: now,
            }}>
                <DateTimeInput source={"completed_at"} label={"Paid at"} defaultValue={moment().format()} parse={(val: any) => moment(val).format()} ></DateTimeInput>
                <TextInput source={"completion_notes"} label={"Notes"} multiline fullWidth></TextInput>
            </SimpleForm>
        </BulkUpdateFormButton>
    );
}

const AbandonButton = () => {
    const userId = useUserId();
    const now = moment().format();
    return (
        <BulkUpdateFormButton resource={"case_actions"} label={"Do not pay – Abandon patent"} icon={<></>} DialogProps={{ title: "Do not pay – Abandon patent" }} >
            <SimpleForm
                toolbar={
                    <Toolbar>
                        <SaveButton label={"Save"} alwaysEnable />
                    </Toolbar>
                }
                sx={{maxWidth: 400}} defaultValues={{
                completed_by: userId,
                completed_at: now,
                annuity_abandoned_by: userId,
                annuity_abandoned_at: now,
            }}>
                <p>Text to explain the consequences</p>
                <TextInput source={"completion_notes"} multiline fullWidth></TextInput>
            </SimpleForm>
        </BulkUpdateFormButton>
    );
}

const PaidElsewhereButton = () => {
    const userId = useUserId();
    const now = moment().format();
    return (
        <BulkUpdateFormButton resource={"case_actions"} label={"Do not pay – Paid through other channels"} icon={<></>} >
            <SimpleForm
                toolbar={
                    <Toolbar>
                        <SaveButton label={"Save"} alwaysEnable />
                    </Toolbar>
                }
                sx={{maxWidth: 400}} defaultValues={{
                completed_by: userId,
                completed_at: now,
                annuity_paid_elsewhere_by: userId,
                annuity_paid_elsewhere_at: now,
            }}>
                <p>Text to explain the consequences</p>
                <TextInput source={"completion_notes"} multiline fullWidth></TextInput>
            </SimpleForm>
        </BulkUpdateFormButton>
    );
}


const InstructionsSentButton = () => {
    const userId = useUserId();
    const now = moment();
    return (
        <BulkUpdateFormButton resource={"case_actions"} label={"Mark as 'instructions sent'"} icon={<></>}>
            <SimpleForm
                toolbar={
                    <Toolbar>
                        <SaveButton label={"Mark as 'instructions sent'"} alwaysEnable />
                    </Toolbar>
                }
                sx={{maxWidth: 400}}
                defaultValues={{
                    annuity_payment_instructions_sent_by: userId,
                    annuity_payment_instructions_sent: true,
                    annuity_payment_instructions_sent_at: now,
                }}>
                <DateTimeInput source={"annuity_payment_instructions_sent_at"} defaultValue={moment().format()} parse={(val: any) => moment(val).format()} ></DateTimeInput>
            </SimpleForm>
        </BulkUpdateFormButton>
    );
}

const ConfirmInstructionsButton = () => {
    const userId = useUserId();
    const now = moment();
    return (
        <BulkUpdateFormButton resource={"case_actions"} label={"Confirm instructions"} icon={<></>}>
            <SimpleForm
                toolbar={
                    <Toolbar>
                        <SaveButton label={"Confirm instructions and inactivate"} alwaysEnable />
                    </Toolbar>
                }
                sx={{maxWidth: 400}}
                defaultValues={{
                    instructions_confirmed_by_payment_agent_by: userId,
                    instructions_confirmed_by_payment_agent_at: now,
                    inactivated_by: userId,
                    inactivated_at: now,
                }}>
                <DateTimeInput source={"instructions_confirmed_by_payment_agent_at"} defaultValue={now.format()} parse={(val: any) => moment(val).format()} ></DateTimeInput>
            </SimpleForm>
        </BulkUpdateFormButton>
    );
}

const ResetStatusButton = () => {
    const userId = useUserId();
    const now = moment();
    return (
        <BulkUpdateFormButton resource={"case_actions"} label={"Reset status"} icon={<></>}>
            <SimpleForm
                toolbar={
                    <Toolbar>
                        <SaveButton label={"Reset status"} alwaysEnable />
                    </Toolbar>
                }
                sx={{maxWidth: 400}}
                defaultValues={{
                    instructions_confirmed_by_payment_agent_by: null,
                    instructions_confirmed_by_payment_agent_at: null,
                    inactivated_by: null,
                    inactivated_at: null,
                    annuity_payment_instructions_sent_by: null,
                    annuity_payment_instructions_sent: false,
                    annuity_payment_instructions_sent_at: null,
                    annuity_paid_elsewhere_by: null,
                    annuity_paid_elsewhere_at: null,
                    annuity_payment_paid: false,
                    annuity_abandoned_by: null,
                    annuity_abandoned_at: null,
                }}>
            </SimpleForm>
        </BulkUpdateFormButton>
    );
}

const bulkActionButtons = (isAdmin: boolean) => <>
    { isAdmin && <ResetStatusButton/>}
    <SendPaymentInstructionsButton/>
    <AnnuityManagementDialogButton />
    { isAdmin && <InstructionsSentButton/> }
    { isAdmin && <ConfirmInstructionsButton /> }
    { isAdmin && <MarkPaidButton/> }
    <AbandonButton/>
    <PaidElsewhereButton/>
    <BulkExportButton></BulkExportButton>
</>;

export const INSTRUCTION_PAY = "Pay";
export const INSTRUCTION_DO_NOT_PAY = "Do not pay";
export const INSTRUCTION_WAIT = "Wait";
export const AnnuityList = (props: any) => {
    const isAdmin = useIsAdmin();
    // const isAdmin = false;
    const { tenant } = React.useContext(TenantContext) || {};
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [instructionsSubmittedSuccessfully, setInstructionsSubmittedSuccessfully] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [instructionsData, setInstructionsData] = React.useState<any>(null);
    const dataProvider = useDataProvider();
    const userId = useUserId();
    const form = useForm();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll("annuities");
    React.useEffect(() => {
        unselectAll()
    }, []);

    const onSubmit = (formData: any) => {
        console.log(formData);

        const instructions = Object.keys(formData)
            .filter((id) => [INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY, INSTRUCTION_WAIT].includes(formData[id]?.conclusion))
            .map((id) => ({
                case_action_id: id,
                instruction: formData[id]?.conclusion,
                instructed_by_user: userId,
                portal_price: formData[id]?.portal_price || null,
            }));

        console.log(instructions, userId);

        const payIds = Object.keys(formData).filter((key) => formData[key]?.conclusion === INSTRUCTION_PAY);
        // const payAnnuities = payIds.map((id) =>
        const dontPayIds = Object.keys(formData).filter((key) => formData[key]?.conclusion === INSTRUCTION_DO_NOT_PAY);
        const waitIds = Object.keys(formData).filter((key) => formData[key]?.conclusion === INSTRUCTION_WAIT);

        console.log("Pay", payIds);
        console.log("Do not pay", dontPayIds);
        console.log("Wait", waitIds);
        const sent_at = moment().format();

        setInstructionsData({
            pay_ids: payIds,
            do_not_pay_ids: dontPayIds,
            wait_ids: waitIds,
            sent_at,
            sent_by: userId,
            instructions,
        })
        setOpen(true);
    }

    const onConfirmed = () => {
        setOpen(false);
        setIsSubmitting(true);
        dataProvider.hasuraRequest({
            query: submitPaymentInstructionsMutation,
            variables: instructionsData
        }).then((data: any) => {
            setIsSubmitting(false);
            console.log(data);
            setInstructionsSubmittedSuccessfully(true);
        }).catch((error: any) => {
            setIsSubmitting(false);
            console.error(error)
            setInstructionsSubmittedSuccessfully(false);
        });
    }

    const onInstructionsSubmitted = () => {
        form.reset();
        refresh();
    }

    const onClose = () => {
        setOpen(false);
    };

    console.log("OPEN: ", open);
    return (
        <Page title={"Annuities"} body={<>
            The table below shows upcoming annuities for all cases. Please insert the due date window, for instance from today and one year ahead and you will show only one due date (the next due date) for each case. Other filters are available via "add filter" above the table on the right side.
            <br/>
            <br/>
            Send instructions to Breeze by selecting the appropriate instruction per annuity in the table below, followed by clicking the "Submit instructions" button at the bottom right corner of your screen.
            <br/>
            <br/>
            Pay: Breeze will pay the annuity on your behalf.
            <br/>
            Do not pay: Breeze will not pay and remove future annuities for this case. This means that you will not receive any reminders for this case in the future.
            <br/>
            Wait: Breeze will not pay the annuity but remind you before the grace period expires.
            <br/>
            <br/>
            {/*The prices are given ex. VAT. Our service fees are subject to 25% VAT (only for Norwegian companies), which will be itemized separately on your invoice.*/}
            {/*<br/>*/}
            {/*<br/>*/}

            {
                tenant?.auto_annuity &&
                <div>
                    <Alert  variant="outlined" severity="info">
                        <AlertTitle>Auto Annuity</AlertTitle>
                        As you have the service level "Auto annuity", we will automatically pay the annuities 2 months before the due date (excluding any annuities you have specifically instructed us to not pay).
                    </Alert>
                    <br/>
                    <br/>
                </div>

            }
            {/*<Link to={'/annuities/handle'}>Handle Annuities</Link>*/}
            {/*<br/>*/}
            {/*<br/>*/}
            Predefined range filters:
            <Stack direction={"row"} spacing={3}>
                <Link to={{
                    pathname: '/annuities',
                    search: `filter=${JSON.stringify({
                        "effective_due_date@_gte": moment("2024-01-01").format("YYYY-MM-DD"),
                        "effective_due_date@_lte": moment("2024-03-31").format("YYYY-MM-DD")
                    })}`,
                }}>Q1 2024</Link>
                <Link to={{
                    pathname: '/annuities',
                    search: `filter=${JSON.stringify({
                        "effective_due_date@_gte": moment("2024-04-01").format("YYYY-MM-DD"),
                        "effective_due_date@_lte": moment("2024-06-30").format("YYYY-MM-DD")
                    })}`,
                }}>Q2 2024</Link>
                <Link to={{
                    pathname: '/annuities',
                    search: `filter=${JSON.stringify({
                        "effective_due_date@_gte": moment("2024-07-01").format("YYYY-MM-DD"),
                        "effective_due_date@_lte": moment("2024-09-30").format("YYYY-MM-DD")
                    })}`,
                }}>Q3 2024</Link>
                <Link to={{
                    pathname: '/annuities',
                    search: `filter=${JSON.stringify({
                        "effective_due_date@_gte": moment("2024-10-01").format("YYYY-MM-DD"),
                        "effective_due_date@_lte": moment("2024-12-31").format("YYYY-MM-DD")
                    })}`,
                }}>Q4 2024</Link>
            </Stack>
            <Stack direction={"row"} spacing={3}>
                <Link to={{
                    pathname: '/annuities',
                    search: `filter=${JSON.stringify({
                        "effective_due_date@_gte": moment("2024-01-01").format("YYYY-MM-DD"),
                        "effective_due_date@_lte": moment("2024-06-30").format("YYYY-MM-DD")
                    })}`,
                }}>H1 2024</Link>
                <Link to={{
                    pathname: '/annuities',
                    search: `filter=${JSON.stringify({
                        "effective_due_date@_gte": moment("2024-07-01").format("YYYY-MM-DD"),
                        "effective_due_date@_lte": moment("2024-12-31").format("YYYY-MM-DD")
                    })}`,
                }}>H2 2024</Link>
                <Link to={{
                    pathname: '/annuities',
                    search: `filter=${JSON.stringify({
                        "effective_due_date@_gte": moment("2024-01-01").format("YYYY-MM-DD"),
                        "effective_due_date@_lte": moment("2024-12-31").format("YYYY-MM-DD")
                    })}`,
                }}>2024</Link>
                <Link to={{
                    pathname: '/annuities',
                    search: `filter=${JSON.stringify({
                        "effective_due_date@_gte": moment("2025-01-01").format("YYYY-MM-DD"),
                        "effective_due_date@_lte": moment("2025-12-31").format("YYYY-MM-DD")
                    })}`,
                }}>2025</Link>
            </Stack>
        </>}>
            { open &&
                <ReviewAndSubmitInstructionsDialog
                    open={open} onClose={onClose}
                    instructionsData={instructionsData}
                    success={instructionsSubmittedSuccessfully}
                    onInstructionsSubmitted={onInstructionsSubmitted}
                />
            }
            <FormProvider {...form} >
                <form onSubmit={form.handleSubmit(onSubmit)}>
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <List {...props} sort={{ field: "effective_due_date,case_ref", order: "ASC,ASC" }} filters={<AnnuityFilter />}
                              exporter={isAdmin ? annuitiesExporter(isAdmin) : annuitiesExporter(isAdmin)}
                              filterDefaultValues={{
                                  "effective_due_date@_gte": moment().format("YYYY-MM-DD"),
                                  "effective_due_date@_lte": moment().add(1, "year").format("YYYY-MM-DD"),
                                  // "completed_at@_is_null": true,
                                  "inactivated_at@_is_null": true,
                              }}
                            // filter={{ annuity_payment_instructions_sent: false }}
                              perPage={25} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
                        >
                            <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
                            <Datagrid bulkActionButtons={isAdmin ? bulkActionButtons(isAdmin) : false} rowSx={annuitiesManagementRowSx(formData)} sx={compactTable} hover={false} optimized>
                                {isAdmin && <TextField source={"tenant_name"} label={"Client"} />}
                                <CaseRefField source={"case_ref"} idSource={"case_id"}></CaseRefField>
                                <TextField source={"tenant_case_ref"} label={"Client ref"} />
                                <FunctionField label={"Type"} render={(record: any) => record.case_ref?.startsWith("P") ? "Patent" : record.case_ref?.startsWith("TM") ? "Trademark" : record.case_ref?.startsWith("D") ? "Design" : "Unknown"}/>
                                <FlagField label={"Country"} source={"country_code"} sx={{ textWrap: "nowrap" }}></FlagField>
                                <TextField source={"applicant"} sx={{ textWrap: "wrap" }}/>
                                <TextField source={"case_entity_size"} label={"Entity size"}/>
                                <TextField source={"application_number"} />
                                {/*<TextField source={"catchword"} />*/}
                                <TextField source={"registration_number"} />
                                <FunctionField
                                    label="Action"
                                    render={(record: any) => record["title"] ? record["title"] : record["action_name"]}
                                />
                                {
                                    isAdmin &&
                                    <FunctionField
                                        label="Our fee"
                                        render={(record: any) => record?.annuity_price?.override_our_fee || record["our_fee"]}
                                    />
                                }
                                {
                                    isAdmin &&
                                    <FunctionField
                                        label="Our fee currency"
                                        render={(record: any) => record?.annuity_price?.override_our_fee_currency || record["our_fee_currency"]}
                                    />
                                }
                                {isAdmin && <TextField source={"official_fee"} />}
                                {isAdmin && <TextField source={"official_fee_per_claim"} />}
                                {isAdmin && <TextField source={"official_fee_currency"} />}
                                {isAdmin && <TextField source={"agent_fee"} />}
                                {isAdmin && <TextField source={"agent_fee_currency"} />}
                                {
                                    tenant?.auto_annuity &&
                                    <FunctionField
                                        label={"Auto annuity"}
                                        render={(record: any) => record.auto_annuity ? "Yes" : "No"}
                                    />
                                }
                                {/*<DateField source={"due_date"} />*/}
                                {/*<DateField source={"with_fine_due_date"} emptyText={"N/A"} />*/}
                                <FunctionField
                                    label={"Due Date"}
                                    render={(record: any) =>
                                        record?.inactivated_at || record?.completed_at ? null :
                                        record?.due_date === record?.effective_due_date ?
                                            <DateField source={"due_date"} /> :
                                            <Chip size={"small"} label={
                                                <Tooltip title={
                                                    <Stack sx={{ fontSize: "1rem", padding: 2 }} spacing={1}>
                                                        <Box>The original due date ({record?.due_date ? moment(record?.due_date).format(dateFormat) : "N/A"}) has passed.</Box>
                                                        {
                                                            record?.effective_due_date ?
                                                                <Box>The grace due date ({ record?.effective_due_date ? moment(record?.effective_due_date).format(dateFormat) : "N/A"}) shown here is the absolute last day for paying the annuity/renewal.</Box> :
                                                                <Box>It looks like the system is missing the grace period due date. Please contact us at post@breezeip.com and we will look into it promptly</Box>
                                                        }

                                                    </Stack>
                                                }>
                                                    <Box>Grace: {record?.effective_due_date ? <DateField source={"effective_due_date"}></DateField> : "N/A"} </Box>
                                                </Tooltip>
                                            } />
                                    }/>

                                <PriceField label={"Price"} textAlign={"right"}></PriceField>

                                {
                                    isAdmin &&
                                    [
                                        <FunctionField
                                            label={"Our USD"}
                                            textAlign={"right"}
                                            render={(record: any) =>
                                                record?.annuity_price?.usd_our_fee?.toLocaleString("nb-NO")
                                            }/>,
                                        <FunctionField
                                            label={"Agent USD"}
                                            textAlign={"right"}
                                            render={(record: any) =>
                                                record?.annuity_price?.usd_agent_fee?.toLocaleString("nb-NO")
                                            }/>,
                                        <FunctionField
                                            label={"Official USD"}
                                            textAlign={"right"}
                                            render={(record: any) =>
                                                record?.annuity_price?.usd_official_fee?.toLocaleString("nb-NO")
                                            }/>,
                                        <FunctionField
                                            label={"Official USD"}
                                            textAlign={"right"}
                                            render={(record: any) =>
                                                <Box>
                                                    <Box>{`${record?.annuity_price?.usd_official_fee?.toLocaleString("nb-NO")}`}</Box>
                                                    {
                                                        (record?.annuity_price?.usd_exchange_rate_official_fee_from_nok || record?.annuity_price?.usd_exchange_rate_official_fee_to_nok) &&
                                                        <>
                                                            {
                                                                record?.annuity_price?.usd_exchange_rate_official_fee_to_nok &&
                                                                <>
                                                                    <Box sx={{ textWrap: "nowrap" }}>{record?.annuity_price?.usd_exchange_rate_official_fee_to_nok?.date || ""}</Box>
                                                                    <Box sx={{ textWrap: "nowrap" }}>1 {record?.annuity_price?.usd_exchange_rate_official_fee_to_nok?.currency_code} ≈ { (1 / (record?.annuity_price?.usd_exchange_rate_official_fee_to_nok?.exchange_rate || 1))?.toFixed(4) } NOK</Box>
                                                                </>
                                                            }
                                                            <Box sx={{ textWrap: "nowrap" }}>{record?.annuity_price?.usd_exchange_rate_official_fee_from_nok?.date || ""}</Box>
                                                            <Box sx={{ textWrap: "nowrap" }}>1 {record?.annuity_price?.usd_exchange_rate_official_fee_from_nok?.currency_code} ≈ { (1 / record?.annuity_price?.usd_exchange_rate_official_fee_from_nok?.exchange_rate)?.toFixed(4) } NOK</Box>
                                                            <Box sx={{ textWrap: "nowrap" }}>1 {record?.annuity_price?.usd_exchange_rate_official_fee_to_nok?.currency_code} ≈ { (record?.annuity_price?.usd_exchange_rate_official_fee_from_nok?.exchange_rate / (record?.annuity_price?.usd_exchange_rate_official_fee_to_nok?.exchange_rate || 1))?.toFixed(4) } USD</Box>
                                                            <Box sx={{ textWrap: "nowrap" }}>1 {record?.annuity_price?.usd_exchange_rate_official_fee_to_nok?.currency_code} ≈ { ((1 + record?.annuity_price?.used_admin_fee_factor) * record?.annuity_price?.usd_exchange_rate_official_fee_from_nok?.exchange_rate / (record?.annuity_price?.usd_exchange_rate_official_fee_to_nok?.exchange_rate || 1))?.toFixed(4) } USD (ink. påslag)</Box>
                                                            <Box sx={{ textWrap: "nowrap" }}>Total ≈ { (record?.annuity_price?.usd_official_fee / (record?.annuity_price?.usd_exchange_rate_official_fee_from_nok?.exchange_rate / record?.annuity_price?.usd_exchange_rate_official_fee_to_nok?.exchange_rate))?.toFixed(4) } {record?.annuity_price?.usd_exchange_rate_official_fee_to_nok?.currency_code} (ink. påslag)</Box>
                                                        </>
                                                    }
                                                </Box>
                                            }/>,
                                        <FunctionField
                                            label={"Our NOK"}
                                            textAlign={"right"}
                                            render={(record: any) =>
                                                <Box>
                                                    <Box>{`${record?.annuity_price?.nok_our_fee?.toLocaleString("nb-NO")}`}</Box>
                                                    {
                                                        record?.annuity_price?.exchange_rate_our_fee &&
                                                        <>
                                                            <Box sx={{ textWrap: "nowrap" }}>{record?.annuity_price?.exchange_rate_our_fee?.date || ""}</Box>
                                                            <Box sx={{ textWrap: "nowrap" }}>1 {record?.annuity_price?.exchange_rate_our_fee?.currency_code} ≈ { ((1) / record?.annuity_price?.exchange_rate_our_fee?.exchange_rate)?.toFixed(4) } NOK</Box>
                                                            <Box sx={{ textWrap: "nowrap" }}>1 {record?.annuity_price?.exchange_rate_our_fee?.currency_code} ≈ { ((1 + record?.annuity_price?.used_admin_fee_factor) / record?.annuity_price?.exchange_rate_our_fee?.exchange_rate)?.toFixed(4) } NOK (ink. påslag)</Box>
                                                        </>
                                                    }
                                                </Box>
                                            }/>,
                                        <FunctionField
                                            label={"Official NOK"}
                                            textAlign={"right"}
                                            render={(record: any) =>
                                                <Box>
                                                    <Box>{`${record?.annuity_price?.nok_official_fee?.toLocaleString("nb-NO")}`}</Box>
                                                    {
                                                        record?.annuity_price?.exchange_rate_official_fee &&
                                                        <>
                                                            <Box sx={{ textWrap: "nowrap" }}>{record?.annuity_price?.exchange_rate_official_fee?.date || ""}</Box>
                                                            <Box sx={{ textWrap: "nowrap" }}>1 {record?.annuity_price?.exchange_rate_official_fee?.currency_code} ≈ { ((1) / record?.annuity_price?.exchange_rate_official_fee?.exchange_rate)?.toFixed(4) } NOK</Box>
                                                            <Box sx={{ textWrap: "nowrap" }}>1 {record?.annuity_price?.exchange_rate_official_fee?.currency_code} ≈ { ((1 + record?.annuity_price?.used_admin_fee_factor) / record?.annuity_price?.exchange_rate_official_fee?.exchange_rate)?.toFixed(4) } NOK (ink. påslag)</Box>
                                                        </>
                                                    }
                                                </Box>
                                            }/>,
                                        <FunctionField
                                            label={"Agent NOK"}
                                            textAlign={"right"}
                                            render={(record: any) =>
                                                <Box>
                                                    <Box>{`${record?.annuity_price?.nok_agent_fee?.toLocaleString("nb-NO")}`}</Box>
                                                    {
                                                        record?.annuity_price?.exchange_rate_agent_fee && record?.annuity_price?.nok_agent_fee ?
                                                            <>
                                                                <Box sx={{ textWrap: "nowrap" }}>{record?.annuity_price?.exchange_rate_agent_fee?.date || ""}</Box>
                                                                <Box sx={{ textWrap: "nowrap" }}>1 {record?.annuity_price?.exchange_rate_agent_fee?.currency_code} ≈ { ((1) / record?.annuity_price?.exchange_rate_agent_fee?.exchange_rate)?.toFixed(4) } NOK</Box>
                                                                <Box sx={{ textWrap: "nowrap" }}>1 {record?.annuity_price?.exchange_rate_agent_fee?.currency_code} ≈ { ((1 + record?.annuity_price?.used_admin_fee_factor) / record?.annuity_price?.exchange_rate_agent_fee?.exchange_rate)?.toFixed(4) } NOK (ink. påslag)</Box>
                                                            </> : null
                                                    }
                                                </Box>
                                            }/>,
                                        <FunctionField
                                            label={"Used Valutapåslag"}
                                            textAlign={"right"}
                                            render={(record: any) =>
                                                `${record?.annuity_price?.used_admin_fee_factor?.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2})}`
                                            }/>
                                    ]
                                }
                                <WrapperField label="Status">
                                    <AnnuityStatus></AnnuityStatus>
                                </WrapperField>
                                <WrapperField label="Instructions">
                                    <Box sx={{textWrap: "nowrap" }} display={"flex"} alignItems={"center"}>
                                        <AnnuityConclusionInput></AnnuityConclusionInput>
                                        <Box>
                                            <ResetAnnuityConclusionButton setValue={form.setValue}></ResetAnnuityConclusionButton>
                                        </Box>
                                    </Box>
                                </WrapperField>
                                { isAdmin && <ShowCountryRuleButton></ShowCountryRuleButton>}
                                {/*<TextField source={"completion_notes"} label={"Notes"}/>*/}
                                {/*<CaseButton />*/}
                            </Datagrid>
                            <WithListContext render={({ data, isLoading, selectedIds }) => (
                                isLoading || !data ? null :
                                    <Stack direction={"row"} justifyContent={"center"} padding={5} spacing={5}>
                                        {
                                            selectedIds?.length > 0 &&
                                            <Box typography="h6">Sum selected ({data.filter((a: any) => selectedIds.includes(a.id)).length}/{data.length}): {tenant?.currency_code} {data.filter((a: any) => selectedIds.includes(a.id)).reduce((acc, annuity) => acc + annuity.annuity_price?.[`${tenant?.currency_code?.toLowerCase()}_price`], 0).toLocaleString("nb-NO")}</Box>
                                        }
                                        <Box typography="h6" >Sum all {data.length}: {tenant?.currency_code} {data.reduce((acc, annuity) => acc + ((isGrace(annuity) ? annuity.annuity_price?.[`${tenant?.currency_code?.toLowerCase()}_grace_price`] : annuity.annuity_price?.[`${tenant?.currency_code?.toLowerCase()}_price`]) || 0), 0).toLocaleString("nb-NO")}</Box>
                                    </Stack>
                            )} />

                            <WithListContext render={({ data, isLoading, selectedIds }) => (
                                isLoading || !data ? null :
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => {
                                            const pay = Object.keys(formData).filter((key) => formData[key]?.conclusion === INSTRUCTION_PAY);
                                            // const sumFees = pay.reduce((acc, key) => acc + formData[key]?.annuity_price?.[`${tenant?.currency_code?.toLowerCase()}_price`], 0);
                                            const doNotPay = Object.keys(formData).filter((key) => formData[key]?.conclusion === INSTRUCTION_DO_NOT_PAY);
                                            const wait = Object.keys(formData).filter((key) => formData[key]?.conclusion === INSTRUCTION_WAIT);
                                            const sum = pay.length + doNotPay.length + wait.length;
                                            const sumTotal = totalAmount(data.filter((line: any) => pay.includes(line.id)), tenant?.currency_code || "");
                                            return (
                                                <Box>
                                                    <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, color: "black" }}>
                                                        <Toolbar>
                                                        {/*<Toolbar sx={{ backgroundColor: "#ccc" }}>*/}
                                                            <Stack display={"flex"} flex={1}>
                                                                <Box display={"flex"} flex={1} alignItems={"center"}>
                                                                    {/*<Chip avatar={<Avatar>?</Avatar>} label="Help" color={"primary"}/>*/}
                                                                    {/*<Chip*/}
                                                                    {/*    label="Help"*/}
                                                                    {/*    color={"primary"}*/}
                                                                    {/*    onClick={() => 1}*/}
                                                                    {/*    onDelete={() => 1}*/}
                                                                    {/*    deleteIcon={<HelpIcon></HelpIcon>}*/}
                                                                    {/*/>*/}
                                                                    <ButtonDialog buttonTitle={"Terms and conditions"} dialogTitle={"Terms and conditions"}>
                                                                        <>
                                                                            Terms are in progress
                                                                        </>
                                                                    </ButtonDialog>
                                                                    <Box flex={1}></Box>
                                                                    { sum > 0 && <Chip label={`Reset instructions (${sum})`} sx={{marginRight: 5}} onDelete={() => form.reset()} onClick={() => form.reset()}></Chip> }

                                                                    <Stack direction={"row"} sx={{marginRight: 5}} spacing={2} >
                                                                        <Chip label={`Wait ${wait.length}`} sx={{ backgroundColor: warningLight }}></Chip>
                                                                        <Chip label={`Don't pay ${doNotPay.length}`} sx={{ backgroundColor: errorLight }}></Chip>
                                                                        <Chip label={`Pay ${pay.length} – ${tenant?.currency_code} ${sumTotal?.toLocaleString("nb-NO")} in total`} sx={{ backgroundColor: successLight }}></Chip>
                                                                        {/*<Chip label={`Pay ${pay.length}`} sx={{ backgroundColor: successLight }}></Chip>*/}
                                                                        {/*{ sumTotal ? <Chip label={`${tenant?.currency_code} ${sumTotal?.toLocaleString("nb-NO")}`}></Chip> : null }*/}
                                                                    </Stack>
                                                                    <SaveButton label={"Submit instructions"} icon={<></>} ></SaveButton>
                                                                </Box>
                                                                {/*<Stack direction={"row"} justifyContent={"flex-end"}>*/}
                                                                {/*    <Typography variant={"body1"}>*/}
                                                                {/*        Keep in mind th*/}
                                                                {/*    </Typography>*/}
                                                                {/*</Stack>*/}
                                                            </Stack>
                                                        </Toolbar>
                                                    </AppBar>
                                                </Box>
                                            );
                                        }}
                                    </FormDataConsumer>
                            )} />
                        </List>

                    )}
                </FormDataConsumer>


                <Box height={"128px"}></Box>
                </form>
            </FormProvider>
            {/*</Form>*/}
        </Page>
    );
};

const ResetAnnuityConclusionButton = (props: any) => {
    const record = useRecordContext();
    if (!record?.id) return null;

    return (
        <FormDataConsumer>
            {({ formData, ...rest }) => (

                    <IconButton sx={{visibility: formData[record.id]?.conclusion ? "inline-flex" : "hidden" }} aria-label="Reset draft instruction" size="large" title={"Reset draft instruction"} onClick={() => props.setValue(`${record.id}.conclusion`, null)}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
            )}
        </FormDataConsumer>
    )
}

const totalAmount = (data: Array<any>, currency_code: string) => data
    .reduce((acc, annuity) => {
        const price = isGrace(annuity) ? annuity.annuity_price?.[`${currency_code?.toLowerCase()}_grace_price`] : annuity.annuity_price?.[`${currency_code?.toLowerCase()}_price`];
        return acc + (price || 0);
    }, 0);


const isGrace = (record: any) => record?.due_date !== record?.effective_due_date;
const PriceField = (props: any) => {
    const { tenant } = useTenantContext() || {};
    const record = useRecordContext();

    if (record?.inactivated_at || record?.completed_at) {
        return null;
    }

    const originalPrice = record?.annuity_price?.[`${tenant?.currency_code?.toLowerCase()}_price`];
    const gracePrice = record?.annuity_price?.[`${tenant?.currency_code?.toLowerCase()}_grace_price`];
    const price_source = isGrace(record) ?
        `annuity_price.${tenant?.currency_code?.toLowerCase()}_grace_price` :
        `annuity_price.${tenant?.currency_code?.toLowerCase()}_price`;

    const currencyOptions = {
        style: 'currency',
        currency: tenant?.currency_code?.toUpperCase(),
        currencyDisplay: "code",
        minimumFractionDigits: 0
    };
    console.log(record, price_source);
    return (
        <Stack direction={"row"} sx={{textAlign: "right", justifyContent: "flex-end" }}>
            {
                isGrace(record) ?
                    <Box>
                        <Box>
                            {
                                gracePrice ?
                                    <Chip size={"small"} label={
                                        <Tooltip title={
                                            <Stack sx={{ fontSize: "1rem", padding: 2 }} spacing={1}>
                                                <Box>Within the grace period, prices are subject to an increase</Box>
                                                <Box>For this specific maintenance fee, the increase is {record?.annuity_price?.grace_price_increase_factor * 100 || "N/A"}%</Box>
                                                <Box>Original price was {originalPrice || "N/A"}. Grace price is now {gracePrice || "N/A"}</Box>
                                            </Stack>
                                        }>

                                            {/*<Box>Grace</Box>*/}
                                            <Box>Grace</Box>
                                            {/*<Box>Grace: <NumberField source={price_source} options={currencyOptions} {...props} /></Box>*/}
                                        </Tooltip>
                                    } />
                                    :
                                    <Tooltip title={
                                        <Stack sx={{ fontSize: "1rem", padding: 2 }} spacing={1}>
                                            <Box>Within the grace period, prices are subject to an increase</Box>
                                            <Box>For this specific maintenance fee, we currently do not have a grace price. Please contact us and we will add it to the system</Box>
                                            <Box>Original price was {originalPrice || "N/A"} {currencyOptions.currency || ""}</Box>
                                        </Stack>
                                    }>

                                        {/*<Box>Grace</Box>*/}
                                        <Box>N/A</Box>
                                        {/*<Box>Grace: <NumberField source={price_source} options={currencyOptions} {...props} /></Box>*/}
                                    </Tooltip>
                            }
                        </Box>
                        <Box>
                            <NumberField source={price_source} options={currencyOptions} {...props} />
                            <NumberInput source={`${record.id}.portal_price`} defaultValue={gracePrice} sx={{ display: "none" }}></NumberInput>
                        </Box>
                    </Box>
                    :
                    <Box>
                        <NumberField source={price_source} options={currencyOptions} {...props} />
                        <NumberInput source={`${record.id}.portal_price`} defaultValue={originalPrice} sx={{ display: "none" }}></NumberInput>
                    </Box>
            }
        </Stack>
    );
}
