import {buildFields, BuildFields, FetchType, ResourceType} from 'ra-data-hasura';
import gql from 'graphql-tag';

/**
 * Extracts just the fields from a GraphQL AST.
 * @param {GraphQL AST} queryAst
 */
const extractFieldsFromQuery = (queryAst: any) => {
    return queryAst.definitions[0].selectionSet.selections;
};

const ORDER = gql`
    {
        user {
            id
            name
        }
        tenant {
            id
            name
        }

        portal_price
        price
        price_currency

        pct_order {
            user_reference
            user_comments
            answers
            
            case {
                case_ref
                tenant_case_ref
                application_number
                registration_number
                country_code

                national_phase_cases {
                    id
                    case_ref
                    application_number
                    registration_number
                    country_code
                }
            }
            
            pct_order_lines {
                country_code
                answers
                price
                price_currency
                service_fee
                translation_fee
                official_fee
                agent {
                    id
                    name
                }
            }
        }
    }
`;


const INSTRUCTIONS_RECEIVED_EVENT = gql`
{
    user {
        id
        name
    }
    instructions_received {
        id
        instruction
        case_action {
            case {
                tenant {
                    name
                }
            }
        }
    }
}
`;


const IMPORT_SESSIONS_EXTRA = gql`
  {
    cases_aggregate {
      aggregate {
        count
      }
    }
  }
`;
const ADD_NEXT_MAINTENENCE = gql`
    {
        next_maintenance_case_action {
            id,
            due_date,
            with_fine_due_date
        }
    }
`;

// Define the additional fields that we want.
const ADD_ACTION_RULE_INFO = gql`
  {
    action_rule {
      id
      action_name
      application_type
      application_type_inverse
      active
      action_type
      action_code
      status
      responsible_user_id
    }
  }
`;

const ADD_ACTION_COUNTRY_RULE_INFO = gql`
  {
    action_country_rule {
      id
      official_fee
      official_fee_currency
      our_fee
      our_fee_currency
      override_action_name
    }
    exchange_rate_official_fee_from {
      id,
      currency_name,
      currency_code,
      exchange_rate,
      date
    }
    exchange_rate_official_fee_to {
      id,
      currency_name,
      currency_code,
      exchange_rate,
      date
    }
  }
`;

const ADD_ANNUITY_ORDER_LINES = gql`
{
    id
    price
    case_action {
        action_rule {
            action_name
        }
        case {
            case_ref
            application_number
            registration_number
            country_code
            catchword
        }
        due_date
    }
}
`

const ADD_INSTRUCTIONS_DETAILS = gql`
    {
        id
        case_action {
            action_rule {
                action_name
            }
            case {
                case_ref
                tenant_case_ref
                applicant
                application_number
                registration_number
                country_code
                catchword
                tenant_id
                tenant {
                    name
                }
            }
            due_date
            with_fine_due_date
            instructions_confirmed_by_payment_agent_at
            instructions_confirmed_by_payment_agent_by
            completed_by
            completed_at
            completion_notes
            annuity_payment_paid
        }
        user {
            name
        }
    }
`

const ADD_ACTION_SUB_RULE = gql`
{
    action_sub_rule {
        code
        action_name
        status
        responsible_user_id
        internal_start_date_logic
        internal_due_date_logic
        inactivates_action_rule
        email_template_id
        complete_button_text
    }
    case_action {
        due_date
        case_id
    }
    user {
        name
    }
}
`

const addAnnuityPriceObject = (isAdmin: boolean) => gql`
{
    annuity_price {
        id,
        nok_price,
        nok_grace_price,
        grace_price_increase_factor
        ${isAdmin ? `
        nok_our_fee,
        nok_agent_fee,
        nok_official_fee,
        ` : ""}
        usd_price,
        usd_grace_price,
        ${isAdmin ? `
        usd_our_fee,
        usd_agent_fee,
        usd_official_fee,
        used_admin_fee_factor,
        override_our_fee,
        override_our_fee_currency,
        
        exchange_rate_our_fee {
            date,
            exchange_rate,
            currency_code,
            currency_name
        }
        exchange_rate_official_fee {
            date,
            exchange_rate,
            currency_code,
            currency_name
        }
        exchange_rate_agent_fee {
            date,
            exchange_rate,
            currency_code,
            currency_name,
        }
        
        usd_exchange_rate_official_fee_from_nok {
            date,
            exchange_rate,
            currency_code,
            currency_name
        }
        usd_exchange_rate_official_fee_to_nok {
            date,
            exchange_rate,
            currency_code,
            currency_name
        }

        
        ` : ""}
    }
}
`

const exludedByDefault: { [key: string]: Array<string> } = {
    "annuities": ["agent_fee_nok", "official_fee_nok", "our_fee_nok", "total_price_nok"],
}

// @ts-ignore
const customBuildFields: BuildFields = (isAdmin: boolean) => (type: ResourceType, fetchType: FetchType) => {
    const resourceName = type.name;

    // First take the default fields (all, but no related or nested).
    // @ts-ignore
    let defaultFields = buildFields(type, fetchType);

    // console.log(resourceName, fetchType, type);

    const excludedFields = exludedByDefault[resourceName] || [];
    defaultFields = defaultFields.filter((field) => !excludedFields.includes(field?.name?.value));

    if (
        ['action_country_rules', "case_actions"].includes(resourceName) &&
        ['GET_LIST', "GET_ONE", "GET_MANY", "GET_MANY_REFERENCE"].includes(fetchType)
    ) {
        const relatedEntities = extractFieldsFromQuery(ADD_ACTION_RULE_INFO);
        defaultFields.push(...relatedEntities);

        if (resourceName === "case_actions" && isAdmin) {
            defaultFields.push(...extractFieldsFromQuery(ADD_ACTION_COUNTRY_RULE_INFO));
        }
    }

    if (resourceName === "annuity_order_lines") {
        defaultFields.push(...(extractFieldsFromQuery(ADD_ANNUITY_ORDER_LINES)));
    }

    if (resourceName === "instructions_received") {
        defaultFields.push(...(extractFieldsFromQuery(ADD_INSTRUCTIONS_DETAILS)));
    }

    if (resourceName === "case_sub_actions") {
        defaultFields.push(...(extractFieldsFromQuery(ADD_ACTION_SUB_RULE)));
    }

    if (
        ['import_sessions'].includes(resourceName) &&
        ['GET_LIST', "GET_ONE", "GET_MANY", "GET_MANY_REFERENCE"].includes(fetchType) &&
        isAdmin
    ) {
        const relatedEntities = extractFieldsFromQuery(IMPORT_SESSIONS_EXTRA);
        defaultFields.push(...relatedEntities);
    }

    if (
        ['annuities'].includes(resourceName) &&
        ['GET_LIST', "GET_ONE", "GET_MANY", "GET_MANY_REFERENCE"].includes(fetchType)
    ) {
        const relatedEntities = extractFieldsFromQuery(addAnnuityPriceObject(isAdmin));
        defaultFields.push(...relatedEntities);
    }

    if (resourceName === "cases") {
        const relatedEntities = extractFieldsFromQuery(ADD_NEXT_MAINTENENCE);
        defaultFields.push(...relatedEntities);
    }

    if (resourceName === "instructions_received_event") {
        const relatedEntities = extractFieldsFromQuery(INSTRUCTIONS_RECEIVED_EVENT);
        defaultFields.push(...relatedEntities);
    }

    if (resourceName === "orders") {
        const relatedEntities = extractFieldsFromQuery(ORDER);
        defaultFields.push(...relatedEntities);
    }

    return defaultFields;
};

export default customBuildFields;