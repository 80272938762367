import * as React from "react";
import {
    List,
    Datagrid,
    DateField,
    TextField,
    NumberField,
    EditButton,
    SingleFieldList,
    ChipField,
    ReferenceManyField,
    ReferenceField,
    BooleanField,
    ImageField,
    Filter,
    TextInput,
} from 'react-admin';
import {useIsAdmin} from "../auth/utils";


const TenantsFilter = (props: any) => {
    // const isAdmin = useIsAdmin();
    return (
        <Filter {...props}>
            <TextInput source={"name"} alwaysOn />
        </Filter>
    );
};

export const TenantList = (props: any) => (
    <List {...props} sort={{ field: 'name', order: 'ASC' }} filters={<TenantsFilter />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source={"name"}/>
            <TextField source="id" />
            <ImageField source="logo_url" title={"Logo"} label={"Logo"}/>
            <NumberField source="admin_fee_factor" />
            <TextField source="next_case_ref_serial_number" textAlign={"right"}/>
            <DateField source={"created_at"} />
            <DateField source={"updated_at"} />
            <TextField source="products" />
            <TextField source="departments" />
            <ReferenceManyField label="Users" reference="tenant_users" target="tenant_id" >
                <SingleFieldList linkType={false} >
                    <ReferenceField source="user_id" reference="users">
                        <ChipField source="name" />
                    </ReferenceField>
                </SingleFieldList>
            </ReferenceManyField>
            <BooleanField source={"auto_annuity"} />
            <BooleanField source={"calculate_annuity_costs"} />
            <TextField source="annuity_canada_entity_size" />
            <TextField source="annuity_usa_entity_size" />
            <NumberField source="annuity_override_our_fee" />
            <TextField source="annuity_override_our_fee_currency" />
            <NumberField source="pct_fee" />
            <TextField source="currency_code" />
            <EditButton />
        </Datagrid>
    </List>
);
