import {
    countries as countryList,
    epStates,
    INTERNATIONAL_TRADEMARK_APPLICATION,
    pctStates
} from "../../utils/countries";
import {
    CASE_FIELDS,
    CASE_TYPE_DESIGN,
    CASE_TYPE_INFRINGEMENT,
    CASE_TYPE_OPPOSITION,
    CASE_TYPE_TRADEMARK
} from "../CaseList";

export const CASE_STATUS_UNFILED = "Unfiled";
export const CASE_STATUS_PENDING = "Pending";
export const CASE_STATUS_GRANTED = "Granted";
export const CASE_STATUS_CLOSED = "Closed";

export const CASE_STATUS_COMPLETED = "Completed";
export const caseStatusList = [CASE_STATUS_UNFILED, CASE_STATUS_PENDING, CASE_STATUS_GRANTED, CASE_STATUS_CLOSED, CASE_STATUS_COMPLETED];

export const isPCTOrEP = (countryCode: string) => countryCode === "pc" || countryCode === "ep";

export const parseDesignatedStates = (value: string | undefined) => {
    if (value === undefined || value === null || value === "" ) {
        return "";
    }
    const newValue = (value || "")
        .replaceAll(",", " ")
        .replaceAll("  ", " ")
        .replaceAll("  ", " ");
    // console.log("Parse:", value, newValue);
    return newValue;
}
export const casesRedirect = (resource: string, id: string, data: any) =>
    data[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_DESIGN ? 'designs' :
        data[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_TRADEMARK ? 'trademarks' :
            data[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_OPPOSITION ? 'other-cases/opposition' :
                data[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_INFRINGEMENT ? 'other-cases/infringement' :
                    'cases';


export const validateDesignatedStates = (countryCode: string) => (value: string): string | undefined => {
    console.log(value);
    if (value === undefined || value === null || value === "" ) {
        return undefined;
    }
    const epStatesWithCHandLI = {...epStates, ch: "Switzerland", li: "Liechtenstein"}
    const countries = countryCode === INTERNATIONAL_TRADEMARK_APPLICATION ? countryList : countryCode === "ep" ? epStatesWithCHandLI : pctStates;

    const trimmed = (value || "")
        .replaceAll(",", " ")
        .replaceAll("  ", " ")
        .replaceAll("  ", " ");
    const countryCodes = trimmed.split(" ");
    const errors = countryCodes.filter((code) => !countries[code.toLowerCase()])
    console.log(errors);
    if (errors.length > 0) {
        return `Error: The following country codes were not found in the system's country list: ${errors.join(" ")}`
    } else {
        return undefined
    }
}

export const applicationNumberUrl = (applicationNumber: string, countryCode?: string) => {
    return applicationNumber?.startsWith("PCT") ?
        {
            url: `https://patentscope.wipo.int/search/en/detail.jsf?docId=${applicationNumber}`,
            title: "Open in Patentscope"
        } : {
            url: `https://worldwide.espacenet.com/patent/search?q=${countryCode || ""}${applicationNumber}`,
            title: "Open in Espacenet"
        }
}
